<template>
  <div class="search-bar-wrapper d-flex spacebetween">
    <el-form :model="formData" ref="searchForm" class="d-flex flex-wrap">
      <template v-for="(item, index) in searchFormItems">
        <template v-if="item.formtype == 'input'">
          <el-input
            :type="item.type || 'text'"
            :placeholder="item.placeholder"
            v-model="formData[item.prop]"
            :style="item.object.style || ''"
            :disabled="item.object.disabled || false"
            :key="index"
          />
        </template>
        <template v-else-if="item.formtype == 'select'">
          <el-select
            :placeholder="item.placeholder"
            v-model="formData[item.prop]"
            :style="item.object.style || ''"
            :disabled="item.object.disabled || false"
            :clearable="item.object.clearable || false"
            :multiple="item.object.multiple || false"
            :loading="item.object.loading || false"
            :key="item.formtype + index"
          >
            <el-option
              v-for="option in item.options" :key="option[item.object.key || 'value']"
              :label="option[item.object.name || 'name']"
              :value="option[item.object.key||'value']"
              :disabled="option.disabled || false"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else-if="item.formtype == 'daterange'">
          <el-date-picker
            v-model="formData[item.prop]"
            type="daterange"
            range-separator="To"
            :start-placeholder="item.object.startPlaceholder || 'Start Date'"
            :end-placeholder="item.object.endPlaceholder || 'End Date'"
            :style="item.object.style || ''"
            :key="item.formtype + index"
            :value-format="item.object.formatDate || 'yyyy-MM-dd'"
          >
          </el-date-picker>
        </template>
      </template>
    </el-form>
    <div class="d-flex">
      <el-button
        v-for="item in searchBtns"
        :type="item.type"
        :key="item.label"
        :style="item.style || ''"
        @click="clickHandler(item)"
      >
        {{ item.label }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchBtns: {
      type: Array,
      default: () => []
    },
    searchFormItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return  {
      formData: {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      let obj = {}
      this.searchFormItems.forEach((f) => {
        if (f) {
          if (Object.hasOwn(f, 'value')) {
            obj[f.prop] = f.value
          } else {
            obj[f.prop] = ''
          }
        }
      })
      this.$set(this, 'formData', obj)
    },
    clickHandler (item) {
      if (item.methodsType == 'submit') {
        this.$emit('search', this.formData)
      } else if (item.methodsType == 'clear') {
        this.initData()
        this.$emit('search', {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar-wrapper {
  width: 100%;
  background: #fff;
  padding: 20px 0 5px;
  box-sizing: border-box;
  align-items: flex-start;
}
</style>