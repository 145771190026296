<template>
  <div class="bed-arrangement height100percent d-flex spacebetween">
    <div class="bed-arrangement_left height100percent">
      <SearchBar
        :searchBtns="searchBtns"
        :searchFormItems="searchFormItems"
        @search="searchHandler"
        class="bed-arrangement_search"
      />
      <div class="bed-arrangement_left_header d-flex spacebetween mt25">
        <div class="d-flex">
          <el-checkbox v-model="occupied" @change="(val) => searchHandler({})">
            Occupied Bed
          </el-checkbox>
          <el-checkbox
            v-model="empty"
            class="empty-bg"
            @change="(val) => searchHandler({})"
          >
            Empty Bed
          </el-checkbox>
        </div>
        <div>
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-size="bedsPageSize"
            @size-change="sizeChangeHandler"
            @current-change="currentChangeHandler"
            :page-sizes="[40, 80, 120, 160, 200]"
            :current-page.sync = "bedsPage"
            :total="bedsTotal">
          </el-pagination>
        </div>
      </div>
      <div class="bed-arrangement_bedscards">
        <div class="d-flex flex-wrap">
          <BedsCard
            v-for="(item, index) in bedsList"
            :key="item.id"
            :bed="item"
            :class="{'active': currBedsIdx == index}"
            @click.native="selectedBedHandler(index)"
          />
        </div>
      </div>
    </div>
    <div class="bed-arrangement_right">
      <div class="bed-arrangement_bednum mb30">
        {{ selectedBed && selectedBed.name ? selectedBed.name: '-' }}
      </div>
      <BedsInfo :obj="selectedBed.occupied_patient" :infoItems="currentPatientInfoItems" />
      <template v-if="selectedBed.booked_patients.length > 0">
        <BedsInfo
          :title="idx == 0 ? 'Upcoming Patient' : ''"
          v-for="(item, idx) in selectedBed.booked_patients"
          :obj="item"
          :isEmpty="true"
          :key="item.id"
          :infoItems="upComingPatientInfoItems"
        />
      </template>
      <BedsInfo
        v-else
        title="Upcoming Patient"
        :isEmpty="true"
        :obj="null"
        :infoItems="upComingPatientInfoItems"
      />
    </div>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar'
import BedsCard from '../components/BedsCard'
import BedsInfo from '../components/BedsInfo'
import {
  getBedList
} from '@/services'
import jsonData from './jsonData'
export default {
  components: {
    SearchBar,
    BedsCard,
    BedsInfo
  },
  data () {
    return {
      searchBtns: jsonData.searchBtns,
      searchFormItems: jsonData.searchFormItems,
      currentPatientInfoItems: JSON.parse(JSON.stringify(jsonData.currentPatientInfoItems)),
      occupied: true,
      empty: true,
      bedsTotal: 0,
      bedsPageSize: 40,
      bedsPage: 1,
      bedsList: [],
      currBedsIdx: -1,
      upComingPatientInfoItems: jsonData.upComingPatientInfoItems,
      selectedBed: {
        booked_patients: [],
        occupied_patient: null
      },
      searchForm: {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    sizeChangeHandler (size) {
      this.bedsPageSize = size
      this.bedsPage = 1
      this.initData(this.searchForm)
    },
    currentChangeHandler (page) {
      this.bedsPage = page
      this.initData(this.searchForm)

    },
    searchHandler (form) {
      this.bedsPage = 1
      this.searchForm = form
      this.initData(form)
    },
    async initData (form = {}) {
      try {
        let statusStr = this.occupied ? '' : 'occupied'
        statusStr = statusStr + (!this.occupied && !this.empty ? ',' : '') + (this.empty ? '' : 'empty')
        statusStr = (this.occupied && this.empty) ? '' : statusStr
        const params = {
          page: this.bedsPage,
          per_page: this.bedsPageSize,
          status_not_in: statusStr,
          active_eq: true,
          ...form
        }
        const res = await getBedList(params)
        if (res.data.beds) {
          this.bedsList = res.data.beds
        }
        
      } catch (error) {}
    },
    selectedBedHandler (idx) {
      this.currBedsIdx = idx
      this.selectedBed = this.bedsList[idx]
      this.$set(this, 'selectedBed', this.bedsList[idx])
    }
  }
}
</script>

<style lang="scss" scoped>
.bed-arrangement {
  width: 100%;
  align-items: flex-start;
  &_left {
    min-width: 650px;
    width: calc(100% - 415px);
  }
  &_search {
    width: 100%;
    padding: 20px;
  }
  &_right {
    width: 390px;
    background: #fff;
    padding: 36px 24px 0;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
  }
  &_bedscards {
    margin-top: 22px;
    height: calc(100% - 57px - 94px);
    overflow-y: auto;
    align-items: flex-start;
  }
  &_bednum {
    font-size: 32px;
    color: $text-color-black;
  }
}
</style>