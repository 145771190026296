export default {
  searchFormItems: [
    {
      formtype: 'input',
      prop: 'patient_beds_patient_ic_cont',
      label: '',
      placeholder: 'Patient IC',
      object: {
        style: 'width: 250px; margin-right: 12px;'
      }
    },
    {
      formtype: 'input',
      prop: 'name_cont',
      label: '',
      placeholder: 'Bed Number',
      object: {
        style: 'width: 250px;margin-right: 14px;'
      }
    }
  ],
  searchBtns: [
    {
      type: 'primary',
      label: 'Search',
      style: 'width: 98px;',
      methodsType: 'submit'
    },
    {
      type: '',
      label: 'Clear',
      style: 'width: 98px;margin-left: 12px;',
      methodsType: 'clear'
    }
  ],
  currentPatientInfoItems: [
    {
      label: 'Referring Institution',
      prop: 'from_institution'
    },
    {
      label: 'Actual Admission Date',
      prop: 'actual_admission_date'
    },
    {
      label: 'Upcoming TCU Date',
      prop: 'tcu_planned_at'
    },
    {
      label: 'Planned Discharge Date',
      prop: 'planned_discharge_date'
    },
    {
      label: 'MDRO',
      prop: 'mdro',
      valclass: 'textdanger'
    }
  ],
  upComingPatientInfoItems: [
    {
      label: 'Referring Institution',
      prop: 'from_institution'
    },
    {
      label: 'Planned Admission Date',
      prop: 'planned_admission_date'
    },
    {
      label: 'MDRO',
      prop: 'mdro',
      valclass: 'textdanger'
    }
  ]
}