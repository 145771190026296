<template>
  <div
    v-if="bed"
    class="bedscard"
    :class="{'bedscard_empty': bed.status == 'empty', 'bedscard_occupied': bed.status == 'occupied'}"
  >
    <template v-if="bed.status == 'occupied' && bed.occupied_patient">
      <div class="bedscard_user d-flex">
        <div class="bedscard_user_name">{{ bed.occupied_patient.name }}</div>
        <div class="bedscard_user_gender d-flex">
          {{ cumputedGender(bed.occupied_patient.gender) }}
        </div>
      </div>
      <div class="bedscard_ic">{{ computedIc(bed.occupied_patient.ic) }}</div>
      <div v-if="bed.occupied_patient.mdro" class="bedscard_mdro textdanger">MDRO</div>
      <div class="bedscard_statuses d-flex flex-wrap">
        <div
          v-if="bed.occupied_patient.legends && bed.occupied_patient.legends.tcu_legend"
          class="bedscard_status bedscard_status_tcu d-flex"
        >
          TCU
        </div>
        <div
          v-if="bed.occupied_patient.legends && bed.occupied_patient.legends.review_legend"
          class="bedscard_status bedscard_status_review d-flex"
        >
          Review
        </div>
        <div
          v-if="bed.occupied_patient.legends && bed.occupied_patient.legends.discharge_legend"
          class="bedscard_status bedscard_status_discharge d-flex"
        >
          Discharge
        </div>
      </div>
    </template>
    <template 
      v-if="bed.status == 'empty' && bed.booked_patients && bed.booked_patients.length > 0"
    >
      <div class="bedscard_user d-flex">
        <div class="bedscard_user_name">{{ bed.booked_patients[0].name }}</div>
        <div
          class="bedscard_user_gender d-flex" 
          :class="{'textdanger' : bed.booked_patients[0].gender == 'female'}"
        >
          {{ cumputedGender(bed.booked_patients[0].gender) }}
        </div>
      </div>
      <div class="bedscard_empty_txt">Upcoming</div>
    </template>
    <div class="bedscard_number">{{ bed.name }}</div>
  </div>
</template>

<script>
import utils from '@/utils/Utils'
export default {
  props: {
    bed: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  computed : {
    cumputedGender () {
      return function (gender) {
        return gender == 'male' ? 'M' : 'F'
      }
    },
    computedIc () {
      return function (ic) {
        return utils.encryptedStr(ic)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bedscard {
  width: 183px;
  background: #fff;
  border-radius: 4px;
  border-top: 4px solid #fff;
  padding: 10px 8px 0 10px;
  min-height: 124px;
  position: relative;
  box-sizing: border-box;
  margin-right: 12px;
  margin-bottom: 12px;
  &:hover {
    box-shadow: 5px 8px 18px 0px rgba($color: #273A4E, $alpha: 0.26);
  }
  &_empty {
    border-top-color: $empty-color;
    &.active {
      border: 2px solid $empty-color;
      border-top-width: 4px;
    }
    &_txt {
      color: $empty-color;
      font-size: 12px;
      font-weight: 500;
    }
  }
  &_occupied {
    border-top-color: $primary-color;
    &.active {
      border: 2px solid $primary-color;
      border-top-width: 4px;
    }
  }
  &_user {
    height: 18px;
    &_name {
      font-weight: bold;
      font-size: 12px;
      color: $text-color-black;
    }
    &_gender {
      width: 18px;
      margin-left: 4px;
      background: #efefef;
      padding: 0 4px;
      color: $bed-blue-color;
      font-weight: bold;
      font-size: 10px;
      justify-content: center;
    }
  }
  &_ic {
    color: $text-color-primary;
    font-size: 10px;
    line-height: 14px;
  }
  &_mdro {
    font-weight: bold;
    font-size: 10px;
    color: $danger-color;
  }
  &_statuses {
    margin-top: 4px;
  }
  &_status {
    padding: 0 8px;
    margin-right: 4px;
    border-radius: 3px;
    // font-weight: bold;
    font-size: 10px;
    color: #fff;
    height: 16px;
    margin-bottom: 5px;
    box-sizing: border-box;
    &_tcu {
      background: $bed-tcu-color;
    }
    &_review {
      background: $bed-review-color;
    }
    &_discharge {
      background: $bed-discharge-color;
      margin-right: 0;
    }
  }
  &_number {
    position: absolute;
    bottom: 4px;
    right: 8px;
    font-weight: bold;
    font-size: 17px;
    color: $text-color-black;
  }
}
</style>