<template>
  <div class="bedsinfo" :class="{'bedsinfo_smb': isEmpty}">
    <div v-if="title" class="bedsinfo_header d-flex spacebetween">
      <div class="bedsinfo_header_title" :class="{'bedsinfo_header_empty': isEmpty}">{{ title }}</div>
    </div>
    <template v-if="obj">
      <div class="d-flex spacebetween">
        <div class="textdarkblue-bold16 alink" @click="$router.push({name: 'wrokplacePatientInfoDetails', query: {id: obj.id}})">
          {{obj.name}}
        </div>
        <div class="d-flex">
          <span class="bedsinfo_label">RAF：</span>
          <span class="bedsinfo_txt">{{obj.raf}}</span>
        </div>
      </div>
      <div class="d-flex bedsinfo_patient">
        <span>{{computedGender(obj.gender)}},</span>
        <span>{{ computedIc(obj.ic) }}</span>
      </div>
      <div>
        <div v-for="(item, index) in infoItems" class="d-flex bedsinfo_item" :key="index">
          <div class="bedsinfo_label" :style="`width: ${labelWidth};`">{{item.label}}：</div>
          <div class="bedsinfo_txt" :class="item.valclass || ''">{{cumputedProp(item)}}</div>
        </div>
      </div>
    </template>

    <div v-else class="bedsinfo_empty">No Data</div>
  </div>
</template>

<script>
import utils from '@/utils/Utils'
export default {
  props: {
    title: {
      type: String,
      default: 'Current Patient'
    },
    infoItems: {
      type: Array,
      default: () => []
    },
    labelWidth: {
      type: String,
      default: '170px'
    },
    obj: {
      type: Object,
      default: null
    },
    isEmpty: {
      type: Boolean,
      default: false
    }
  },
  computed : {
    computedIc () {
      return function (ic) {
        return utils.encryptedStr(ic)
      }
    },
    cumputedProp () {
      const { obj } = this
      return function (info) {
        return utils.getComputedValFromProp(info, obj)
      }
    },
    computedGender () {
      return function (gender) {
        return gender ? (gender == 'male' ? 'Male' : 'Female') : '-'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bedsinfo {
  width: 100%;
  font-size: 14px;
  margin-bottom: 40px;
  &_smb {
    margin-bottom: 24px;
  }
  &_header {
    width: 100%;
    padding-bottom: 8px;
    border-bottom: $border-style;
    margin-bottom: 12px;
    &_title {
      font-size: 16px;
      font-weight: 500;
      color: $text-color-black;
    }
    &_empty {
      color: $empty-color;
    }
  }
  &_empty {
    padding: 0 40px;
    text-align: center;
    color: #979797;
  }
  &_txt {
    color: #000;
  }
  &_label {
    color: $text-color-secondarygray;
    line-height: 20px;
  }
  &_item {
    margin-top: 6px;
  }
}
</style>